.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #fff;
  color: #3c4858;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
  font-family: "Lato", sans-serif;
}

h6 {
  font-size: 1em;
  /* text-transform: uppercase; */
  font-weight: 500;
}

body {
  background-color: #fff;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  /* color: #26c6da; */
  text-decoration: none;
}

a:hover,
a:focus {
  /* color: #00d3ee; */
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {

  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}

.fixed-plugin {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li>a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu>.active>a,
.fixed-plugin .dropdown-menu>.active>a:hover,
.fixed-plugin .dropdown-menu>.active>a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li>a:hover,
.fixed-plugin .dropdown-menu li>a:focus {
  box-shadow: none;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}

.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}

.fixed-plugin .adjustments-line .dropdown-menu>li.adjustments-line>a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder img {
  margin-top: auto;
}

.fixed-plugin .dropdown-menu>li:hover>a.img-holder,
.fixed-plugin .dropdown-menu>li:focus>a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu>.active>a.img-holder,
.fixed-plugin .dropdown-menu>.active>a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  -moz-transform: translateY(-15%);
  -o-transform: translateY(-15%);
  -ms-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}

.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  -moz-transform: translateY(-13%);
  -o-transform: translateY(-13%);
  -ms-transform: translateY(-13%);
  transform: translateY(-13%);
  transform-origin: 0 0;
}

* {
  letter-spacing: normal !important;
}

/*------------ ----------------- --------------*/
/*------------custom style --------------*/
/*----- font family ----------*/
/*------ font-face ---------*/
@font-face {
  font-family: 'Gordita';
  src: url('../fonts/gordita/Gordita-BoldItalic.eot');
  src: url('../fonts/gordita/Gordita-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-BoldItalic.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-BoldItalic.woff') format('woff'),
    url('../fonts/gordita/Gordita-BoldItalic.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-BoldItalic.svg#Gordita-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url('../fonts/gordita/Gordita-Black.eot');
  src: url('../fonts/gordita/Gordita-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-Black.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-Black.woff') format('woff'),
    url('../fonts/gordita/Gordita-Black.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-Black.svg#Gordita-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url('../fonts/gordita/Gordita-BlackItalic.eot');
  src: url('../fonts/gordita/Gordita-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-BlackItalic.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-BlackItalic.woff') format('woff'),
    url('../fonts/gordita/Gordita-BlackItalic.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-BlackItalic.svg#Gordita-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url('../fonts/gordita/Gordita-LightItalic.eot');
  src: url('../fonts/gordita/Gordita-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-LightItalic.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-LightItalic.woff') format('woff'),
    url('../fonts/gordita/Gordita-LightItalic.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-LightItalic.svg#Gordita-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url('../fonts/gordita/Gordita-Light.eot');
  src: url('../fonts/gordita/Gordita-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-Light.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-Light.woff') format('woff'),
    url('../fonts/gordita/Gordita-Light.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-Light.svg#Gordita-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url('../fonts/gordita/Gordita-Bold.eot');
  src: url('../fonts/gordita/Gordita-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-Bold.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-Bold.woff') format('woff'),
    url('../fonts/gordita/Gordita-Bold.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-Bold.svg#Gordita-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url('../fonts/gordita/Gordita-Medium.eot');
  src: url('../fonts/gordita/Gordita-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-Medium.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-Medium.woff') format('woff'),
    url('../fonts/gordita/Gordita-Medium.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-Medium.svg#Gordita-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url('../fonts/gordita/Gordita-MediumItalic.eot');
  src: url('../fonts/gordita/Gordita-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-MediumItalic.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-MediumItalic.woff') format('woff'),
    url('../fonts/gordita/Gordita-MediumItalic.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-MediumItalic.svg#Gordita-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url('../fonts/gordita/Gordita-RegularItalic.eot');
  src: url('../fonts/gordita/Gordita-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-RegularItalic.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-RegularItalic.woff') format('woff'),
    url('../fonts/gordita/Gordita-RegularItalic.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-RegularItalic.svg#Gordita-RegularItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url('../fonts/gordita/Gordita-Thin.eot');
  src: url('../fonts/gordita/Gordita-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-Thin.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-Thin.woff') format('woff'),
    url('../fonts/gordita/Gordita-Thin.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-Thin.svg#Gordita-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url('../fonts/gordita/Gordita-Regular.eot');
  src: url('../fonts/gordita/Gordita-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-Regular.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-Regular.woff') format('woff'),
    url('../fonts/gordita/Gordita-Regular.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-Regular.svg#Gordita-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url('../fonts/gordita/Gordita-ThinItalic.eot');
  src: url('../fonts/gordita/Gordita-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-ThinItalic.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-ThinItalic.woff') format('woff'),
    url('../fonts/gordita/Gordita-ThinItalic.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-ThinItalic.svg#Gordita-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita Ultra';
  src: url('../fonts/gordita/Gordita-UltraItalic.eot');
  src: url('../fonts/gordita/Gordita-UltraItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-UltraItalic.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-UltraItalic.woff') format('woff'),
    url('../fonts/gordita/Gordita-UltraItalic.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-UltraItalic.svg#Gordita-UltraItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita Ultra';
  src: url('../fonts/gordita/Gordita-Ultra.eot');
  src: url('../fonts/gordita/Gordita-Ultra.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gordita/Gordita-Ultra.woff2') format('woff2'),
    url('../fonts/gordita/Gordita-Ultra.woff') format('woff'),
    url('../fonts/gordita/Gordita-Ultra.ttf') format('truetype'),
    url('../fonts/gordita/Gordita-Ultra.svg#Gordita-Ultra') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*------ font-face ---------*/
.MuiTypography-root,
.MuiListItemText-root,
.MuiTab-root,
.MuiTableCell-root,
.MuiButton-root {
  font-family: "Gordita" !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gordita" !important;
}

/*------------ admin form style -------------*/
.formGrid .formGrid-inner {
  /* width: 100%; */
  font-family: "Gordita" !important;
  /* margin-bottom: 24px !important; */
  position: relative !important;
}

.formGrid .formGrid-inner label {
  font-weight: 500;
  color: #0E101A;
  opacity: 0.72;
}

.formGrid .formGrid-inner label,
.formGrid .formGrid-inner .inputField input {
  font-family: "Gordita" !important;
}

.formGrid .formGrid-inner .MuiInput-formControl::after,
.formGrid .formGrid-inner .MuiInput-formControl::before {
  display: none;
}

.formGrid .formGrid-inner .MuiInput-formControl {
  border: 1px solid #C8CFD5;
  border-radius: 8px;
  padding: 10px 16px 9px;
  margin-top: 20px;
  font-size: 16px;
  height: 48px;
  width: 100%;
}

.formGrid .formGrid-inner .inputField.selectTextField {
  padding: 0;
  width: 100%;
}

.formGrid .formGrid-inner .inputField.selectTextField .MuiInputBase-root-MuiOutlinedInput-root {
  height: 48px;
}

.formGrid .formGrid-inner .inputField.selectTextField .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.formGrid .formGrid-inner .inputField.selectTextField input {
  padding: 3.5px 4px 7.5px 6px;
}

.multiSelect-Textfield .MuiInput-formControl {
  padding: 4px 16px !important;
}

/* .multiSelect-Textfield{
  margin-top: 14px !important;
} */
.multiSelect-Textfield .MuiOutlinedInput-root {
  padding: 5px !important;
  border-radius: 8px !important;
}

.multiSelect-Textfield fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid #C8CFD5 !important;
}

.uncheckedCircle-icon {
  color: #6A8CAA;
}

.checkedCircle-icon {
  /* color: #08D6A8; */
  color: #235D93;
}

.MuiAutocomplete-root {
  width: 100%;
}

.MuiAutocomplete-tag {
  /* background: #08D6A8 !important; */
  background: #235D93 !important;
  border-radius: 8px !important;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 32px;
  color: #FFFFFF !important;
}

.multiSelect-Textfield .MuiAutocomplete-inputRoot {
  margin-top: 0px !important;
}

/*--------- add branch style ------------*/
.branch-container {
  background: #FFFFFF;
  border: 2px solid transparent;
  border-radius: 12px;
  padding: 24px;
  position: relative;
  margin-bottom: 24px;
  box-shadow: 0px 0px 8px rgba(23, 47, 69, 0.08);
}

.branch-container .branch-count {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0E101A;
  margin-bottom: 0;
}

.branch-container .main-branch {
  position: absolute;
  top: -2px;
  right: 50px;
  width: 96px;
  padding: 9px 12px;
  background: #F3F9FF;
  border-radius: 0px 0px 8px 8px;

  font-weight: 500;
  text-align: center;
  font-size: 12px;
  line-height: 17px;
  color: #6A8CAA;
}

.branch-container .closebtn {
  position: absolute;
  right: 24px;
  top: 24px;
}

.branch-container .closebtn img {
  width: 12px;
  height: 12px;
}

.addBranchBtn {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6A8CAA !important;
  padding: 0 24px 0 0 !important;
  font-family: "Gordita";
  text-transform: capitalize !important;
  cursor: pointer;
  margin-bottom: 24px !important;
}

.addBranchBtn img {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

/*------------- button style ------------*/
.primaryButton {
  background-color: #548CA8 !important;
  border-radius: 8px !important;
  min-width: 140px !important;
  padding: 11px 12px !important;
  font-size: 14px !important;
  font-family: 'Gordita' !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
}

.innerBackBtn {
  margin-bottom: 20px !important;
}

.action-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-top: 48px;
  margin-bottom: 20px;
}

.action-div .nextBtn {
  font-size: 14px;
  margin-left: unset;
  margin-bottom: 0px !important;
  color: #fff !important;
}

.action-div .previousBtn {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6A8CAA;
  padding: 0 24px 0 0 !important;
  font-family: "Gordita";
  text-transform: capitalize !important;
  cursor: pointer;
}

.updated-dot {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #FF9E2D;
  position: absolute;
  margin: 9px 9px 9px 6px;
}

/*-------------- custom editor style -------------*/
.editorBox .quill {
  margin-top: 20px;
  width: 100%;
}

.editorBox .ql-toolbar.ql-snow {
  border-bottom: 0px;
  border: 1px solid #C8CFD5;
  border-radius: 8px 8px 0 0;
  background: #ECF6FF;
}

.editorBox .ql-container {
  height: auto;
  min-height: 100px;
}

.editorBox .ql-container.ql-snow {
  border: 1px solid #C8CFD5;
  border-radius: 0 0 8px 8px;
}

/*-------------- custom editor style end-------------*/
.MuiRadio-colorSecondary .MuiSvgIcon-root {
  fill: #548CA8 !important;
}

/*----------- role tab style --------------*/
.titlehead {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #0E101A;
  font-family: 'Gordita' !important;
}

.primaryButton .MuiButton-startIcon {
  margin-right: 0px !important;
}

.primaryButton .MuiSvgIcon-root {
  width: 14px;
  height: 14px;
}

.MuiAccordion-root {
  box-shadow: none !important;
}

.MuiAccordionSummary-root {
  height: 48px;
  background-color: rgba(106, 140, 170, 0.08) !important;
  border-radius: 8px !important;
}

.MuiAccordion-root:before,
.MuiAccordion-root:after {
  display: none;
}

.MuiAccordionSummary-root.Mui-expanded {
  background-color: #476072 !important;
  border-radius: 8px 8px 0px 0px !important;
  color: #fff;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #548CA8 !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #548CA8 !important;
}

.MUIDataTableToolbar-iconActive-114 {
  color: #548CA8 !important;
}

.MUIDataTableSearch-clearIcon-251:hover {
  color: #548CA8 !important;
}

.MuiTableCell-head {
  white-space: nowrap;
}

.MuiPaper-rounded {
  border-radius: 8px !important;
}

@media (min-width: 600px) {
  .MuiToolbar-gutters {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.dashboradTitle {
  color: #6A8CAA;
  font-weight: 500;
  font-size: 18px;
}

/*----- phone input style --------------*/
.phoneInputField.react-tel-input {
  margin-top: 20px;
}

.phoneInputField.react-tel-input .special-label {
  top: -23px;
  left: 0;
  background: none !important;
  font-weight: 500;
  color: #0E101A;
  opacity: 0.72;
}

.phoneInputField.react-tel-input .form-control {
  padding: 14px 14px 14px 58px;
  border: 1px solid #C8CFD5;
  border-radius: 8px;
  font-size: 16px;
  height: 48px;
  width: 100%;
  background: none;
}

.formControl.selectCategory .inputField {
  border: unset;
  border-radius: 0;
  padding: 0;
  margin-top: 0;
  font-size: 12px;
  font-weight: 500;
}

.masterDialogBox .MuiDialog-paper {
  max-height: unset !important;
}

/*----- phone input style end--------------*/
.formGrid .formGrid-inner .multiSelect-Textfield .MuiInput-formControl {
  height: auto;
}

/*----------- master dialog box style ----------------*/
.masterDialogBox .MuiDialog-paper {
  overflow: hidden;
}

.masterDialogBox .MuiDialogContent-root {
  max-height: 450px;
  overflow-y: auto;
}

@media (max-width: 1024px) {
  .masterDialogBox .MuiDialogContent-root {
    max-height: 350px;
  }
}

/* admin table */
.master-table .MuiTableCell-root {
  background-color: transparent;
}

/* AsynAutocomplete Autocomplete */
/* .MuiAutocomplete-popper {
  top: 63px !important;
} */


.master-table .MuiIconButton-root:hover {
  color: #548CA8 !important;
}

.master-table .MuiSvgIcon-root {
  color: #548CA8 !important;
}