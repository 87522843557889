/* Loader CSS Start */
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid rgb(128, 208, 248);
    border-color: rgb(145, 203, 233) transparent rgb(147, 186, 206) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Loader CSS End */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.MuiPickersDay-daySelected {
    background-color: rgb(25, 118, 210) !important;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-text {
    cursor: text !important;
}

/* .ps {
  position: static !important;
}

.sf-wrapper {
  height: 100vh;
  min-height: 100vh;
} */


/* header */

/* .MuiAppBar-positionFixed {
    position: fixed !important;
}

.MuiDrawer-paperAnchorLeft {
    z-index: 9999 !important;
    position: relative;
    box-shadow: none !important;
} */