.loginpage{
    width: 100%;
    position: relative;
    font-family: "Gordita";
    height: 100vh;
    overflow: auto;
} 
.left-container{
    width: 42%;
    /* background: url('../img/login-bg.png') no-repeat; */
    background-size: cover;
    background-position: bottom;
    padding: 40px;
    position: relative;
}
.loginpage .left-container .bg-element{
    position: absolute;
    top: 0;
    right: -50px;
    z-index: 1;
}
.left-container .logo{
    width: 200px;
    margin-bottom: 24px;
}
.left-container p{
    font-size: 16px;
    line-height: 24px;
    color: rgb(255 255 255 / 72%);
}
.left-container .link-container{
    position: absolute;
    bottom: 40px;
}
.right-container .link-container p{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #343434;
    margin-bottom: 0;
}
.right-container .link-container .signUp-link{
    font-weight: 500;
    color: #548CA8;
}
.loginpage .right-container{
    width: 58%;
    padding: 30px 140px 90px 174px;
    position: relative;
    font-family: "Gordita" !important;
    background: #F9FCFF;
    overflow: hidden;
}
.loginpage .right-container.signUp-container{
    padding: 30px 140px 90px 124px;
}
.loginpage .right-container .bg-element{
    position: absolute;
    bottom: 0;
    right: 0;
}
.right-container .login-nav{
    text-align: end;
}
.right-container .login-nav .nav-link{
    color: #6A8CAA;
    padding: 5px 20px;
    position: relative;
    display: inline-flex;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
}
.right-container .login-nav .nav-link:hover, .right-container .login-nav .nav-link.active {
    color: #000;
}
.right-container .login-nav .nav-link::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background-color: #548CA8;
    height: 6px;
    width: 6px;
    border-radius: 100%;
    margin: 0 auto;
    bottom: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}
.right-container .login-nav .nav-link:hover::before{
    opacity: 1;
}
.right-container .form-container{
    display: flex;
    align-items: center;
    height: 100%;
    width: 370px;
}
.right-container.signUp-container .form-container{
    width: 570px;
}
.right-container .loginForm{
    width: 100%;
}
.right-container .loginForm .title{
    font-weight: 900;
    font-size: 32px;
    line-height: 52px;
    color: #0E101A;
    margin-bottom: 40px;
}
.right-container .verifyMailBox{
    width: 370px;
}
 .formControl{
    width: 100%;
    font-family: "Gordita" !important; 
    margin-bottom: 24px !important;   
    position: relative !important;
}
 .formControl label{
    font-weight: 500;
    color: #0E101A;
    opacity: 0.72;
}
 .formControl label, 
 .formControl .inputField input{
    font-family: "Gordita" !important;
}
 .formControl .inputField::after, 
 .formControl .inputField::before{
    display: none;
}
.formControl .inputField.selectTextField {
    padding: 0;
}
.formControl .inputField.selectTextField .MuiInputBase-root-MuiOutlinedInput-root{
    height:48px;
}
.formControl .inputField.selectTextField .MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.formControl .inputField.selectTextField input{
    padding: 3.5px 4px 7.5px 6px;
}
.right-container .loginForm .formControl .forget-link{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #548CA8;
}

.media-btn{
    width: 48%;
    font-family: "Gordita" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #343434b8 !important;
    text-transform: unset !important;
    padding: 12px !important;
    justify-content: start !important;
    box-shadow: 0px 4px 16px rgba(22, 75, 122, 0.10);
    background: #fff;
    margin: 24px 0 20px !important;
    border-radius: 8px !important;
}
.media-btn span{
    margin-right: 8px;
}
.loginForm .media-btn{
    justify-content: center !important;
}
.right-container .loginForm .formControl p{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #343434;
    margin-bottom: 0;
}
.right-container .loginForm .formControl.subtext p{
    color: rgba(52, 52, 52, 0.64);
}
.right-container .loginForm .formControl.subtext img{
    width: auto;
    height: 80px;
}

.select-role, .details-container{
    margin: 32px 0 0;
}
.select-role h6, .details-container h6{
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    color: #0E101Ab8;
    margin-bottom: 16px;
}
.select-role .role-container{
    display: flex;
    justify-content: space-between;
}
.select-role .role-container a{
    text-decoration: none;
}
.select-role .role-container .role-div{
    width: 160px;
    height: 160px;
    background: #FFFFFF;
    border: 2px solid #FFF;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    box-shadow: 0px 4px 16px rgba(22, 75, 122, 0.08);
}
.select-role .role-container .role-div.active, 
.select-role .role-container .role-div:hover{
    border: 2px solid #548CA8;
}
.select-role .role-container .role-div img{
    height: 64px;
    margin-bottom: 8px;
}
.select-role .role-container .role-div.active img, 
.select-role .role-container .role-div:hover img{
    filter: unset;
    /* filter: saturate(7); */
}

.select-role .role-container .role-div p{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6A8CAA;
    margin-bottom: 0;
}
.select-role .role-container .role-div.active p, 
.select-role .role-container .role-div:hover p{
    color: #0E101A;
}
/* .action-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 48px;
    margin-bottom: 20px;
} */
.checkboxInput span{
    font-size: 12px;
    font-family: 'Gordita';
}
/* .action-div .nextBtn{
    font-size: 14px;
    margin-left: auto;
    margin-bottom: 0px !important;
} */

.skipNowBtn{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6A8CAA !important;
    padding: 0 24px 0 0 !important;
    font-family: "Gordita";
    text-transform: capitalize !important;
    margin-right: auto !important;
}
.action-div .previousBtn{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6A8CAA;
    padding: 0 24px 0 0 !important;
    font-family: "Gordita";
    text-transform: capitalize !important;
}
.resetAction-div{
    justify-content: flex-end;
}
.browseText{
    font-family: "Gordita";
    width: fit-content;
    position: absolute !important;
    bottom: 0;
    right: 0;
    height: 46px;
    padding: 0 24px !important;
    background-color: rgba(57, 161, 255, 0.08) !important;
    border-radius: 0 8px 8px 0 !important;
    color: #6A8CAA !important;
    text-transform: capitalize !important;
    display: flex;
    align-items: center;
}

.formControl .inputField .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input{
    font-family: "Gordita";
}
.formControl .inputField .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus{
    background-color: #f9fcff;
}
.formControl .inputField .MuiSelect-icon.MuiSvgIcon-root{
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.474117 0.518441C0.644567 0.344573 0.911292 0.328767 1.09925 0.471023L1.15309 0.518441L5.00016 4.44283L8.84723 0.518441C9.01768 0.344573 9.2844 0.328767 9.47236 0.471023L9.52621 0.518441C9.69666 0.692309 9.71215 0.964384 9.57269 1.15611L9.52621 1.21104L5.33965 5.48156C5.1692 5.65543 4.90248 5.67123 4.71452 5.52898L4.66067 5.48156L0.474117 1.21104C0.286622 1.01978 0.286622 0.709696 0.474117 0.518441Z' fill='%23164B7AA3'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    color: transparent;
}
/* .formControl .inputField .css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon{
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.474117 0.518441C0.644567 0.344573 0.911292 0.328767 1.09925 0.471023L1.15309 0.518441L5.00016 4.44283L8.84723 0.518441C9.01768 0.344573 9.2844 0.328767 9.47236 0.471023L9.52621 0.518441C9.69666 0.692309 9.71215 0.964384 9.57269 1.15611L9.52621 1.21104L5.33965 5.48156C5.1692 5.65543 4.90248 5.67123 4.71452 5.52898L4.66067 5.48156L0.474117 1.21104C0.286622 1.01978 0.286622 0.709696 0.474117 0.518441Z' fill='%23164B7AA3'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    color: transparent;
    transform: rotate(180deg);
} */
.checkboxInput .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
    color: rgb(8 217 171 / 60%);
}
.checkboxInput .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, 
.checkboxInput .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
    color: #08D6A8 !important;
}

/**-------------------------------------------------------------------**/
/**------------------- responsive style ------------**/
/**-------------------------------------------------------------------**/

@media (max-width: 1280px){
    .loginpage .right-container.signUp-container{
        padding: 30px 80px 90px 80px;
    }
}

@media (max-width: 1200px){
    .loginpage .right-container{
        padding: 30px 40px 40px;
    }
    .right-container.signUp-container .form-container{
        width: 500px;
    }
    .select-role .role-container .role-div{
        width: 140px;
        height: 140px;
    }
    .select-role .role-container .role-div p{
        margin-top: 0px;
    }
    .loginpage .right-container .bg-element{
        display: none;
    }
}
@media screen and (max-width: 1024px){
    .right-container.signUp-container .form-container{
        width: 400px;
    }
    .select-role .role-container .role-div{
        width: 120px;
        height: 120px;
    }
    .select-role .role-container .role-div p{
        margin-top: 0px;
    }
    .signupForm .formGrid{
        display: block !important;
    }
    .signupForm .formGrid .formGrid-inner{
        width: 100%;
    }
}
@media  (max-width: 992px) {

    .right-container.signUp-container .form-container{
        width: 370px;
    }
    .loginpage .right-container.signUp-container {
        padding: 30px 40px 40px;
    }
}












