/*---------------- project detail page style ----------------*/
.pagetitle-container h3{
    margin-bottom: 0;
}
.projectDetail-card{
    background: #FFFFFF;
    border: 1px solid rgba(57, 161, 255, 0.16);
    box-sizing: border-box;
    border-radius: 12px !important;
    box-shadow: none !important;
}
.projectDetail-card .css-46bh2p-MuiCardContent-root{
    padding: 40px !important;
}
.projectDetail-card .project-top{
    display: flex;
    align-items: center;
}
.projectDetail-card .icon-container{
    width: 48px;
    height: 48px;
    background: rgba(56, 162, 255, 0.08);
    border-radius: 50px;
    vertical-align: middle;
    display: flex;
    margin-right: 24px;
}
.projectDetail-card .icon-container .project-icon{
    width: 20px;
    margin: auto;
}
.projectDetail-card .icon-container p{
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #6A8CAA;
    margin: auto;
}
.projectDetail-card .project-title p{
    margin-bottom: 0;
    margin-top: 0;
}
.projectDetail-card .project-title .name{
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #0E101A;
}
.projectDetail-card .project-title .category{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(52, 52, 52, 0.72);
}
.projectImage_slider .item{
    width: 180px !important;
    height: 180px !important;
    object-fit: contain;
}
.projectImage_slider .slick-slide img{
    width: 100%;
    height: 100%;
}
.project-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #6a8caa14;
    border-radius: 8px;
    padding: 4px 12px;
}
.project-link img{
    width: auto;
}
.project-duration{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #343434;
    opacity: 0.72;
}

/*---------------- project detail page style end ----------------*/
/*---------------- project panel style --------------------*/
.project_detail-table{
    border-collapse: unset !important;
    border-spacing: 0px 10px !important;
    margin-bottom: 24px;
}
.project_detail-table .tableCellHead{
    padding: 8px 12px;
    color: #343434b8;
    border-bottom: 1px solid #F3F9FF;
    font-family: 'Gordita';
}
.project_detail-table .tableTitle{
    font-family: 'Gordita';
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    color: #0E101A;
    margin-bottom: 0px;
    border-bottom: none !important;
    padding: 0px;
}
.project_detail-table .tableCellHead{
    background:#F3F9FF;
}
.project_detail-table .tableCellHead:first-child{
    border-radius: 8px 0 0 8px;
    width: 70%;
}
.project_detail-table .tableCellHead:last-child{
    border-radius: 0 8px 8px 0;
}
.project_detail-table .tableDataCell{
    font-family: 'Gordita';
    border-top: 1px solid rgba(99, 180, 253, 0.16);
    border-bottom: 1px solid rgba(99, 180, 253, 0.16);
    padding: 4px 12px;
}
.project_detail-table .tableDataCell:first-child{
    border-left: 1px solid rgba(99, 180, 253, 0.16);
    border-radius: 8px 0 0 8px;
}
.project_detail-table .tableDataCell:last-child{
    border-right: 1px solid rgba(99, 180, 253, 0.16);
    border-radius: 0 8px 8px 0;
    /* text-align: right; */
}
.project_detail-table .tableDataCell .actionBtn{
    color: #6A8CAA;
}
.project_detail-table .tableDataCell .actionBtn .actionLink{
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #6A8CAA !important;
    text-decoration: none;
}
.project_detail-table.certificate_table .tableDataCell{
    padding: 8px 12px;
}
.project_detail-table.certificate_table .tableCellHead:first-child{
    width: 10%;
}
.project_detail-table.certificate_table .tableCellHead:nth-last-child(2){
    width: 25%;
}
.project_detail-table.certificate_table .tableCellHead:last-child{
    width: 5%;
}
.certificate_table .certificateImg{
    max-width: 65px;
    height: 48px;
}
.certificate_table .certificateImg img{
    height: 100%;
    width: 75px;
}
.project_detail-table.certificate_table .MuiTableRow-root.MuiTableRow-hover:hover{
    box-shadow: 0px 8px 24px rgb(0 42 112 / 6%);
    background-color: #fff;
}
/*---------------- project panel style end--------------------*/
/*----------------- manage developer table style-----------------*/
.project_detail-table.manage_developer-table .tableCellHead:first-child{
    width: 15%;
}
.project_detail-table.manage_developer-table .tableDataCell{
    padding: 15px 12px;
    color: #343434;
    cursor: pointer;
}
.project_detail-table.manage_developer-table .tableDataCell.statusText p{
    width: fit-content;
    padding: 4px 16px;
    border-radius: 8px;
    margin: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.project_detail-table.manage_developer-table .tableDataCell.statusText .pending{
    color: #FF9903;
    background: rgba(255, 144, 41, 0.08);
}
.eyeIcon{
    cursor: pointer;
}

.project_detail-table.manage_developer-table .tableDataCell.statusText .approved{
    color: #7EBF14;
    background: rgba(126, 191, 20, 0.08);
}
.project_detail-table.manage_developer-table .tableDataCell.statusText .rejected{
    color: #F90E38;
    background: rgba(249, 14, 99, 0.08);
}
.project_detail-table.manage_developer-table .tableDataCell.name{
    color: #0E101A;
}
.project_detail-table.manage_developer-table .MuiTableRow-root.MuiTableRow-hover:hover{
    box-shadow: 0px 8px 24px rgb(0 42 112 / 6%);
    background-color: #fff;
}
/*----------------- manage developer table style end-----------------*/


.project-duretion-month {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.project-duretion-month .formControl .MuiInputAdornment-root {
    width: unset;
    height: 100%;
}
.project-duretion-month .formControl .inputField {
    position: relative;
}
  
.project-duretion-month .month-duration p {
    position: absolute;
    top: 0px;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eaf1f5;
    border-radius: 0px 7px 7px 0px;
    width: 94px;
}
  
.project-duretion-month .MuiFormControlLabel-root {
    margin-left: 17px;
    margin-right: 0;
    margin-top: 24px;
}