.tabs-container{
    background: #476072;
    border-radius: 12px;
}
.tabs-container button{
    padding: 24px !important;
    max-width: 100%;
}
.tabs-container .MuiTabs-indicator{
    display: none;
}
/*-------------- tab style ----------------------*/
.tab-box{
    border-radius: 12px;
    width: 100%;
    display: flex;
    padding: 16px;
    font-family: 'Gordita' !important;
}
.tab-box:hover, 
.tab-box.active{
    background: rgba(255, 255, 255, 0.08);
}
.tab-box .tab-count{
    width: 24px;
    height: 24px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: #fff;
}
.tab-box:hover .tab-count, 
.tab-box.active .tab-count{
    background: #476072;
}
.tab-box .tab-content{
    width: calc(100% - 32px);
    color: #fff;
    text-align: left;
}
.tab-box .tab-content h5{
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize !important;
    color: #FFFFFF;
    margin-bottom: 8px;
}
.tab-box .tab-content p{
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #FFFFFF;
    text-transform: none !important;
    opacity: 0.56;
}
.tab-box .tab-image{
    width: 100%;
    height: 144px;
    text-align: center;
    opacity: 0.56;
}
.tab-box .tab-image img{
    height: 100%;
    width: auto;
}
.tab-box:hover .tab-content p, 
.tab-box.active .tab-content p,
.tab-box:hover .tab-image, 
.tab-box.active .tab-image{
    opacity: 1;
}
.add_developer-panel{
    background: #FFFFFF;
    border: 1px solid rgba(99, 180, 253, 0.16);
    box-sizing: border-box;
    border-radius: 12px;
    margin-left: 24px;
    min-height: 620px;
}
.add_developer-panel .add_developer-form .title{
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #0E101A;
    font-family: 'Gordita' !important;
    margin-bottom: 16px;
}
.panel-container .action-div{
    display: block;
    text-align: end;
    margin-top: 40px;
    justify-content: end;
}
.addproject-box{
    min-height: 240px;
    height: 100%;
    border: 1px dashed rgba(57, 161, 255, 0.4);
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.addproject-box button{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #6A8CAA;
    font-family: 'Gordita';
}
.added-project-box{
    margin-bottom: 24px;
}
/*-------------- tab style end----------------------*/
