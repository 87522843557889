.agency-developer-section {
    padding: 20px 0px 0 0;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    border-radius: 12px;
}


.agency-developer-section .agency-developer-heading {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
}

.agency-developer-section .agency-developer-heading h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    margin: 0;
    color: #0E101A;
}

.agency-developer-section .developer-request-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.agency-developer-section .developer-request-icon .developer-search-icon {
    color: #548CA8;
    padding-right: 15px;
}

.agency-developer-section .developer-request-icon .developer-filter-icon {
    color: #548CA8;
}

.agency-developer-section .select-agency-content {
    padding: 12px 24px 12px 12px;
    background: #F5F8FA;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 24px;
    margin-bottom: 20px;
}

.agency-developer-section .select-agency-content .select-developer-agency-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 50%;
    width: 50%;
}

.agency-developer-section .select-agency-content .agency-auto-complete {
    width: 100%;
    margin-right: 16px;
}

.agency-developer-section .select-agency-content .agency-select-box {
    width: 100%;
}

.agency-developer-section .select-agency-content .agency-select-box .select-custom-agency {
    width: 100%;
    border-radius: 8px;
}

.agency-developer-section .agency-view-btn h5 {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: #6E7377;
    margin: 0;
}


@media(max-width:767px) {
    .agency-developer-section .select-agency-content {
        display: block;
    }

    .agency-developer-section .select-agency-content .select-developer-agency-box {
        width: 100%;
        max-width: 100%;
        display: block;
    }

    .agency-developer-section .select-agency-content .agency-auto-complete {
        margin-bottom: 10px;
    }

    .agency-developer-section .select-agency-content .agency-select-box {
        margin-bottom: 10px;
    }

    .agency-developer-section .agency-view-btn h5 {
        text-align: right;
    }

    .agency-developer-section .agency-developer-heading {
        padding: 0 16px;
    }

    .agency-developer-section .select-agency-content {
        margin: 0 16px 16px 16px;
    }

}

.agency-developer-section .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.agency-developer-section .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline {
    border-color: #548CA8;
}

.agency-developer-section .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #548CA8;
}

.agency-developer-section .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #C8CFD5;
}

.agency-developer-section .agency-text-field .MuiAutocomplete-input {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.agency-developer-section .agency-text-field .MuiInputBase-root {
    border-radius: 8px;
}

.agency-developer-section .select-agency-content .agency-select-box .select-custom-agency .MuiSelect-selectMenu {
    padding-top: 14px;
    padding-bottom: 14px;
}

.agency-developer-section .agency-developer-heading .MuiSvgIcon-root {
    color: #548CA8;
}

.agency-developer-section .agency-developer-heading .custom-search-agencydev {
    width: 46%;
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.single-td-no-data {
    padding: 16px !important;
}

.agency-developer-section .select-agency-content .agency-auto-complete .MuiAutocomplete-popupIndicator {
    display: none;
}

@media(max-width: 767px) {
    .agency-developer-section .agency-developer-heading .custom-search-agencydev {
        width: 100%;
    }
}

.agency-developer-section .select-agency-content:hover {
    transition: all 0.4 ease-in-out;
}

.agency-developer-section .select-agency-content {
    transition: all 0.9s ease-in-out;
}