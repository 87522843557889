
/*------------- add project modal ----------------*/
.add_project-modal .MuiDialog-paper{
    background: #fff;
    border-radius: 12px;
    min-width: 765px;
    font-family: 'Gordita' !important;
    
}
.add_project-modal .modal-title{
    font-weight: 900;
    font-size: 24px;
    line-height: 40px;
    color: #0E101A;
    padding: 40px 40px 16px;
}
.add_project-modal .bottom{
    padding-bottom: 40px !important;
}
.add_project-modal .content{
    padding: 0 40px !important;
}
.aboutSection .aboutContent{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #343434;
    margin-bottom: 0;
    font-family: 'Gordita';
}
.add-image-container{
    margin-bottom: 16px;
}
.add-image-container .label{
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #0E101A;
    opacity: 0.72;
    margin-bottom: 0;
}
.add-image-container .add-imageList{
    display: flex;
}
.add-image-container .add-imageList .item{
    width: 165px;
    height: 120px;
    border-radius: 8px;
    margin-right: 16px;
    padding: 0;
    position: relative;
    overflow: hidden;
}
.add-image-container .add-imageList .item .imageOverlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(106, 140, 170, 0.72);
    color: #fff;
    cursor: pointer;
    opacity: 0;
}
.add-image-container .add-imageList .item .imageOverlay img{
    width: 14px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: brightness(10);
}
.add-image-container .add-imageList .item:hover .imageOverlay{
    opacity: 1;
}
.add-image-container .add-imageList .item img{
    height: 100%;
    width: auto;
}
.add-image-container .add-imageList .add-imageBox{
    width: 165px;
    height: 120px;
    border: 1px dashed rgba(57, 161, 255, 0.4);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.add-image-container .add-imageList .add-imageBox button{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #6A8CAA;
    font-family: 'Gordita';
}
.add_project-modal .bottom{
    display: block;
    text-align: end;
    padding: 8px 98px 40px !important;
}
.adornment-icon{
    cursor: pointer;
    padding: 8px;
    height: initial !important;
}
.add_project-modal .MuiCardContent-root{
    padding: 0px;

}
.add_project-modal .projectDetail-card{
    border: unset;
    background: unset;
}
/*------------- add project modal end----------------*/
/*------------view certificate style --------------*/
.certificateCard.certificateView{
    display: block;
    margin-bottom: 40px;
}
.certificateView .certificateImg{
    margin-right: 0;
    height: 100%;
}
.certificateView .content.detail{
    padding: 0 !important;
}
.certificateView .certificateImg img{
    width: 100%;
    height: 400px;
    object-fit: contain;
}
.certificateView .certificate-title p.date{
    margin: 0 12px 0 auto !important;
}
.certificateView .certificate-title{
    margin: 12px 0;
}
/*------------view certificate style end--------------*/
/*------------- approval modal ----------------*/
.approval_modal .MuiDialog-paper {
    background: #F9FCFF;
    border-radius: 12px;
    min-width: 480px;
    max-width: 480px;
}
.main-assign-modal-agency .MuiDialog-paper{
    max-width: 650px;
    background-color: #fff;
}
.assigned-log-container .assigned-agency .reason{
    padding-top: 0 !important;
}
.approval_modal .modal-title{
    padding: 40px;
}
.approval_modal .modal-title .MuiIconButton-root{
    position: absolute;
    right: 28px;
    top: 26px;
    color: #9e9e9e;
}
.approval_modal .title-img{
    width: 64px;
    height: 64px;
    margin: auto;
}
.approval_modal .content{
    padding: 0 40px 16px !important;
    text-align: center;
}
.approval_modal .content h4{
    font-weight: 900;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #0E101A;
    margin-top: 0;
}
.approval_modal .content span{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #343434;
    word-break: break-all;
}
.approval_modal .bottom{
    padding-bottom: 40px !important;
}
.bottom .modalBtn{
    background: #548CA8;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin: auto;
    text-transform: unset !important;
}
/*------------- approval modal end----------------*/
/*----------- agency log modal ------------*/
.agency-log-modal .modal-title{
    padding: 24px;
}
.agency-log-modal .content{
    padding: 0px 24px 24px !important;
}
.agency-log-modal .modal-title .MuiIconButton-root{
    top: 24px;
}
.agency-log-modal .modal-title h2{
    font-weight: 900;
    font-size: 24px;
    line-height: 40px;
    color: #0E101A;
}
.agency-log-modal .log-profile{
    display: flex;
    align-items: center;
}
.agency-log-modal .log-profile .icon-container{
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 100%;
}
.agency-log-modal .log-profile .icon-container img{
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.agency-log-modal .log-profile-content{
    width: calc(100% - 78px);
    margin-left: 14px;
}
.agency-log-modal .log-profile-content .agency-name{
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #0E101A;
}
.agency-log-modal .log-profile-content .agency-Id{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(14, 16, 26, 0.64);
}
.agency-log-modal .log-profile-content div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.agency-log-modal .log-profile-content div a{
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #548CA8;
}
.assigned-log-container{
    background: #F6F8FA;
    border-radius: 8px;
    padding: 16px;
    margin-top: 32px;
    text-align: left;
}
.assigned-log-container hr{
    margin: 16px 0;
}
.assigned-log-container .assigned-agency .assigned-agency-name{
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #0E101A;
    margin-bottom: 6px;
}
.assigned-log-container .assigned-agency .assigned-duration{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.assigned-log-container .assigned-agency .assigned-duration p{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(14, 16, 26, 0.64);
}
.assigned-log-container .assigned-agency .requested-for-agency{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(14, 16, 26, 0.8);
}
.assigned-log-container .assigned-agency .reason{
    padding-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(14, 16, 26, 0.72);
}
.assigned-log-container .assigned-agency .rejection-title{
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: rgba(14, 16, 26, 0.64);
    margin-bottom: 8px;
}
.assign-new-agency{
    margin-top: 24px;
}
.agency-log-modal .action-div{
    margin-top: 0;
}
.assigned-agency .request-agency{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.assigned-agency .request-agency a{
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #548CA8;
}
/*----------- agency log modal end------------*/


.slick-prev:before, .slick-next:before{
    color: #476072 !important;
 }

 .project-detail-main-modal .content{
    padding: 0 !important;
 }
 .project-detail-main-modal .content .MuiCardContent-root{
    padding: 24px 40px;
 }

/* .MuiAutocomplete-popper{
   z-index: 1500;
} */

.assign-new-agency{
    overflow: visible !important;
}

@media (max-width:767px){
    .main-assign-modal-agency .MuiDialog-paper{
        max-width: 100%;
        min-width: 100%
    }
    .approval_modal .MuiDialog-paper {
        
        min-width: 100%;
        max-width: 100%;
    }
    .MuiDialog-container{
        margin: 10px;
    }
    .agency-log-modal .modal-title h2{
        font-size: 18px;
        line-height: 26px;
    }
    .agency-log-modal .modal-title .MuiIconButton-root{
        top: 15px;
        right: 15px;
    }
    .agency-log-modal .log-profile-content .agency-name{
        font-size: 16px;
        line-height: 22px;
    }
    .agency-log-modal .log-profile-content div{
        display: block;
        text-align: left;
    }
    .agency-log-modal .log-profile-content div a{
        padding-left: 0;
    }
}