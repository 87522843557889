.project-card{
    background: #FFFFFF;
    border: 1px solid rgba(57, 161, 255, 0.16);
    box-sizing: border-box;
    border-radius: 12px !important;
    box-shadow: none !important;
    height: 100%;
}
.project-card .project-top{
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(14 16 26 / 8%);
    padding-bottom: 14px;
}
.project-card .icon-container{
    width: 48px;
    height: 48px;
    background: rgba(56, 162, 255, 0.08);
    border-radius: 50px;
    vertical-align: middle;
    display: flex;
    margin-right: 24px;
}
.project-card .icon-container .project-icon{
    width: 20px;
    margin: auto;
}
.project-card .icon-container p{
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #6A8CAA;
    margin: auto;
}
.project-card .project-title p{
    margin-bottom: 0;
}
.project-card .project-title .name{
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #0E101A;
}
.project-card .project-title .category{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(52, 52, 52, 0.72);
}
.project-card .skillset .profile-subtitle{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #343434;
    margin-bottom: 8px;
    margin-top: 16px;
    font-family: 'Gordita';
}
.project-card .MuiCardContent-root{
    padding-bottom: 0 !important;
}
.skillList{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0px !important;
    flex-wrap: wrap;
    margin-top: 8px !important;
}
.skillList .skillList-item{
    width: auto;
    min-width: 80px;
    height: 32px;
    text-align: center;
    background: rgba(22, 75, 122, 0.08);
    border-radius: 6px;
    margin-right: 12px;
    padding: 5px 20px;
    white-space: nowrap;
    margin-bottom: 12px;
}
.skillList .skillList-item span{
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: rgba(22, 75, 122, 0.64);
    font-family: 'Gordita';
}
.project-card .about_project{
    padding-top: 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(14, 16, 26, 0.64);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.project-card .duration-details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
}
.project-card .duration-details p{
    font-size: 12px;
    line-height: 22px;
    color: rgb(52 52 52 / 72%);
}