.MuiDrawer-modal .MuiDrawer-paper {
    border-radius: 12px 0 0 12px;
    overflow-y: unset;
    max-width: 420px;
    width: 420px;
}

.notification-box {
    /* padding: 32px 24px; */
    position: relative;
}

.notification-box .notification-header {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 32px 24px 32px;
    border-bottom: 1px solid rgba(234, 241, 245, 1);
}

.notification-box .notification-header h2 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    margin: 0;
    color: #0e101a;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-box .notification-header h2 span {
    background-color: #476072;
    border-radius: 48px;
    color: #fff;
    font-size: 12px;
    line-height: 17px;
    padding: 3px 7px;
    margin-left: 6px;
    display: inline-block;
}

.notification-box .notification-header .Mark-btn {
    font-size: 12px;
    line-height: 18px;
    color: #548ca8;
    text-decoration: none;
    font-weight: 500;
}

.notification-box .notification-body {
    padding: 0;
    overflow: auto;
    height: calc(100vh - 97px);
}

.notification-box .notification-body::-webkit-scrollbar {
    width: 5px;
    border-radius: 30px;
}

.notification-box:hover .notification-body::-webkit-scrollbar-thumb {
    background: rgba(14, 16, 26, 0.4);
    border-radius: 50px;
    display: block;
}

.notification-box .notification-body::-webkit-scrollbar-thumb {
    background: rgba(14, 16, 26, 0.4);
    border-radius: 50px;
    opacity: 0;
    display: none;
}

.notification-box .notification-body::-webkit-scrollbar-track {
    background: rgba(14, 16, 26, 0.08);
}

.notification-box .notification-header .notification-link {
    position: absolute;
    top: 25px;
    left: -15px;
}

.notification-box .notification-header .notification-link .close-btn {
    background: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #eaf1f5;
    box-shadow: -8px 0px 24px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-box .notification-body ul {
    padding: 0;
}

.notification-box .notification-body ul li {
    padding: 0;
}

.notification-box .notification-body ul li:last-child .notification-list {
    border: unset;
}

.notification-box .notification-body ul li .notification-list {
    padding: 16px 29px;
    border-bottom: 1px solid #eaf1f5;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-decoration: none;
    cursor: pointer;
}

.notification-box .notification-body ul li.notification-bg {
    background: #f8f9f9;
}

.notification-box .notification-body ul li:hover .notification-list {
    background: #f8f9f9;
}

.notification-box .notification-body ul li .notification-list .Left-profile {
    position: relative;
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 50%;
}

.notification-box .notification-body ul li .notification-list .Left-profile img {
    width: 100%;
    border-radius: 50%;
}

.notification-box .notification-body ul li .notification-list .Left-profile span {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ffa92b;
    border: 2px solid #ffffff;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 100px;
}

.notification-box .notification-body .notification-content {
    padding-left: 16px;
}

.notification-box .notification-body .notification-content p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: rgba(110, 115, 119, 1);
    margin-bottom: 0;
}

.notification-box .notification-body .notification-content p em {
    color: rgba(19, 26, 32, 1);
    font-style: normal;
    font-weight: 700;
}

.notification-box .notification-body .notification-content span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #6e7377;
}

.bell-badge span {
    background-color: #476072;
    color: #fff;
}

.bell-badge img {
    margin: 3px;
}

@media(max-width: 600px) {
    .notification-box .notification-header .notification-link {
        left: 0;
        top: 5px;
    }

    .notification-box .notification-header h2 {
        font-size: 18px;
        line-height: 24px;
    }

    .notification-box .notification-body ul li .notification-list .Left-profile {
        width: 35px;
        height: 35px;
        min-width: 35px;
    }

    .notification-box .notification-body ul li .notification-list .Left-profile span {
        top: -2px;
        left: -3px;
    }

    .notification-box .notification-body ul li .notification-list {
        padding: 10px 0;
    }

    .notification-box .notification-body .notification-content p {
        font-size: 13px;
        line-height: 18px;
    }

    .MuiDrawer-modal .MuiDrawer-paper {
        border-radius: 0;
        max-width: 420px;
        width: 100%;
    }

}

.admin-bellbtn {
    background-color: rgba(106, 140, 169, 0.08) !important;
}

.top-main-header-detail {
    background-color: #fff !important;
    padding-right: 0 !important;
}