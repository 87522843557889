/*------ font-face ---------*/
@font-face {
    font-family: 'Gordita';
    src: url('../fonts/gordita/Gordita-BoldItalic.eot');
    src: url('../fonts/gordita/Gordita-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-BoldItalic.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-BoldItalic.woff') format('woff'),
        url('../fonts/gordita/Gordita-BoldItalic.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-BoldItalic.svg#Gordita-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('../fonts/gordita/Gordita-Black.eot');
    src: url('../fonts/gordita/Gordita-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-Black.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-Black.woff') format('woff'),
        url('../fonts/gordita/Gordita-Black.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-Black.svg#Gordita-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('../fonts/gordita/Gordita-BlackItalic.eot');
    src: url('../fonts/gordita/Gordita-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-BlackItalic.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-BlackItalic.woff') format('woff'),
        url('../fonts/gordita/Gordita-BlackItalic.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-BlackItalic.svg#Gordita-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('../fonts/gordita/Gordita-LightItalic.eot');
    src: url('../fonts/gordita/Gordita-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-LightItalic.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-LightItalic.woff') format('woff'),
        url('../fonts/gordita/Gordita-LightItalic.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-LightItalic.svg#Gordita-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('../fonts/gordita/Gordita-Light.eot');
    src: url('../fonts/gordita/Gordita-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-Light.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-Light.woff') format('woff'),
        url('../fonts/gordita/Gordita-Light.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-Light.svg#Gordita-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('../fonts/gordita/Gordita-Bold.eot');
    src: url('../fonts/gordita/Gordita-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-Bold.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-Bold.woff') format('woff'),
        url('../fonts/gordita/Gordita-Bold.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-Bold.svg#Gordita-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('../fonts/gordita/Gordita-Medium.eot');
    src: url('../fonts/gordita/Gordita-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-Medium.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-Medium.woff') format('woff'),
        url('../fonts/gordita/Gordita-Medium.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-Medium.svg#Gordita-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('../fonts/gordita/Gordita-MediumItalic.eot');
    src: url('../fonts/gordita/Gordita-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-MediumItalic.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-MediumItalic.woff') format('woff'),
        url('../fonts/gordita/Gordita-MediumItalic.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-MediumItalic.svg#Gordita-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('../fonts/gordita/Gordita-RegularItalic.eot');
    src: url('../fonts/gordita/Gordita-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-RegularItalic.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-RegularItalic.woff') format('woff'),
        url('../fonts/gordita/Gordita-RegularItalic.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-RegularItalic.svg#Gordita-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('../fonts/gordita/Gordita-Thin.eot');
    src: url('../fonts/gordita/Gordita-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-Thin.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-Thin.woff') format('woff'),
        url('../fonts/gordita/Gordita-Thin.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-Thin.svg#Gordita-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('../fonts/gordita/Gordita-Regular.eot');
    src: url('../fonts/gordita/Gordita-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-Regular.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-Regular.woff') format('woff'),
        url('../fonts/gordita/Gordita-Regular.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-Regular.svg#Gordita-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('../fonts/gordita/Gordita-ThinItalic.eot');
    src: url('../fonts/gordita/Gordita-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-ThinItalic.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-ThinItalic.woff') format('woff'),
        url('../fonts/gordita/Gordita-ThinItalic.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-ThinItalic.svg#Gordita-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita Ultra';
    src: url('../fonts/gordita/Gordita-UltraItalic.eot');
    src: url('../fonts/gordita/Gordita-UltraItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-UltraItalic.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-UltraItalic.woff') format('woff'),
        url('../fonts/gordita/Gordita-UltraItalic.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-UltraItalic.svg#Gordita-UltraItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita Ultra';
    src: url('../fonts/gordita/Gordita-Ultra.eot');
    src: url('../fonts/gordita/Gordita-Ultra.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gordita/Gordita-Ultra.woff2') format('woff2'),
        url('../fonts/gordita/Gordita-Ultra.woff') format('woff'),
        url('../fonts/gordita/Gordita-Ultra.ttf') format('truetype'),
        url('../fonts/gordita/Gordita-Ultra.svg#Gordita-Ultra') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*-------------- add_profile-page style ---------*/
.profile-top-section {
    background-position: top;
    width: 100%;
    height: 340px;
    background-size: cover;
    position: relative;
    display: block;
    z-index: 1;
}

.profile-top-inner {
    padding-top: 10px;
    align-items: center;
    margin-bottom: 30px;
}

.profile-top-inner h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #0E101A;
}

.breadcrumb-div .breadcrumb-nav {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
}

.breadcrumb-div .breadcrumb-nav .css-1gqyz35-MuiTypography-root {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
}

.breadcrumb-div .breadcrumb-nav .css-1wuw8dw-MuiBreadcrumbs-separator {
    margin-left: 0;
    margin-right: 0;
}

.breadcrumb-div .breadcrumb-nav a:hover,
.breadcrumb-div .breadcrumb-nav a:focus {
    color: #fff;
}

.profile-container {
    position: relative;
    z-index: 2;
    margin-bottom: 40px;
}

.add_profile-container {
    margin-top: -310px;
}

.profile-container h4 {
    font-weight: 900;
    font-size: 24px;
    line-height: 40px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 32px;
    text-align: center;
}

.profile-inner {
    background: #FFFFFF;
    border: 1px solid rgba(99, 180, 253, 0.16);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 24px 24px 0;
}

.add_profile-form {
    width: 100%;
    margin: auto;
}

.add_profile-form .title:first-child {
    margin: 0px 0px 16px;
}

.add_profile-form .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    color: #0E101A;
    margin: 16px 0px 16px;
}

.formGrid {
    justify-content: space-between;
    position: relative;
}

.formGrid .formGrid-inner {
    width: 48%;
}

.formControl textarea {
    border-color: #C8CFD5;
    border-radius: 8px;
    padding: 10px 16px 9px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #343434;
}

.formControl textarea:focus-visible {
    outline: rgba(57, 161, 255, 0.80) !important;
}

.add_profile-form .action-div {
    text-align: end;
    margin-top: 16px;
    justify-content: end;
}

.add_profile-form .action-div .nextBtn {
    margin-left: unset;
}

.formGrid .serialNo {
    width: 48px;
    height: 48px;
    position: absolute;
    left: -60px;
    background: rgb(57 161 255 / 6%);
    border-radius: 8px;
    margin-top: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.formGrid .serialNo p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #0E101A;
    opacity: 0.72;
}

.formGrid .closebtn {
    width: 48px;
    height: 48px;
    position: absolute;
    right: -60px;
    background: rgb(57 161 255 / 6%);
    border-radius: 8px;
    margin-top: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.formGrid .closebtn img {
    width: 12px;
    height: 12px;
}

.addBranchBtn {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6A8CAA !important;
    padding: 0 24px 0 0 !important;
    font-family: "Gordita";
    text-transform: capitalize !important;
}

.addBranchBtn img {
    width: 12px;
    height: 12px;
    margin-right: 8px;
}

/*-------------- add_profile-page style end---------*/

/*---------------- My profile section ---------------*/
.my_profile-container {
    /* margin-top: -340px; */
    max-width: 100% !important;
}

.my_profile-container .userprofile-detail-section {
    color: #fff;
}

.userprofile-detail-section .userDetails .userImage {
    width: 120px;
}

.userprofile-detail-section .userDetails {
    display: flex;
    align-items: center;

}

.userprofile-detail-section .userImage .user {
    width: 120px;
    height: 120px;
    background: rgba(255, 255, 255, 0.24);
    border-radius: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.userprofile-detail-section .userImage .user img {
    width: auto;
    filter: brightness(10);
}

/*--------- more_action --------------*/

.more_action:hover {
    background-color: rgb(255 255 255 / 4%) !important;
}

.more_actionList a {
    text-decoration: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #6A8CAA;
}

.more_actionList a img {
    margin-right: 8px;
}

.userprofile-detail-section .companyDetails {
    padding-left: 144px;
    display: flex;
}

.userprofile-detail-section .companyDetails .formGrid {
    width: calc(100% / 4);
}

.userprofile-detail-section .companyDetails .formGrid label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    font-family: 'Gordita';
}

.userprofile-detail-section .companyDetails .formGrid p {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 0;
}

.my_profile-inner .formGrid .formControl {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px;
}

.my_profile-inner .formGrid .formControl .profile_avatar {
    width: 40px;
    height: 40px;
    background: #548CA814 !important;
    margin-right: 8px;
}

.profile_avatar img {
    height: 16px;
    filter: saturate(7);
}

.my_profile-inner .formGrid .formControl label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0E101A;
    margin-bottom: 4px;
}

.my_profile-inner .formGrid .formControl .value {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #0E101A;
    margin: 0;
}

.personalDetail-section .formGrid .formControl label {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #0E101A;
    opacity: 0.72;
}

.personalDetail-section .formGrid .formControl .value {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0E101A;
}

.userprofile-detail-section .experienceDetails .formGrid {
    width: calc(100% / 5);
}

/*---------------- My profile section end---------------*/
/*------------- added section style --------------*/
.project-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F3F9FF;
    border-radius: 8px;
    padding: 4px 12px;
    word-break: break-all;
    word-wrap: break-word;
}

.my_profile-inner .formGrid .formControl .website-label,
.my_profile-inner .formGrid .formControl .idProof-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #343434;
    opacity: 1;
    font-family: 'Gordita' !important;
}

.my_profile-inner .formGrid .formControl .project-link {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0E101A;
    font-family: 'Gordita' !important;
    text-decoration: none;
}

.my_profile-inner .formGrid .formControl .idProof-name {
    margin-top: 8px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #0E101A;
    font-family: 'Gordita' !important;
}

.my_profile-inner .formGrid .formControl .idProof-name span {
    width: 40px;
    height: 40px;
    background: rgba(106, 140, 170, 0.16);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.my_profile-inner .formGrid .formControl .idProof-name .downloadBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #548CA8;
    text-decoration: none;
    margin-left: 8px;
    cursor: pointer;
}

.moreDetails.manage_users-moreDetails .moreDetails-list {
    display: flex;
}

.moreDetails .moreDetails-list li {
    padding: 15px 24px;
    display: block;
}

.moreDetails .moreDetails-list li p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0E101A;
    opacity: 0.72;
    margin-bottom: 2px;
}

.moreDetails .moreDetails-list li h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #0E101A;
    margin-bottom: 0;
    word-break: break-all;
}

.aprove-reject-btn .approve-btn {
    padding: 0;
    margin-right: 20px;
    background-color: unset;
    border: none;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    line-height: 20px;
    color: #6A8CAA;
    font-weight: 500;
}

.aprove-reject-btn .approve-btn:hover {
    border: none;
}

.aprove-reject-btn .reject-btn {
    background: #548CA8;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    font-weight: 500;
    width: 140px;
    height: 48px;
    border: none;
}

.aprove-reject-btn .reject-btn:hover {
    background-color: #548CA8;
    border: unset !important;
    color: #fff;
}

.fade {
    background: rgba(22, 75, 122, 0.04);
    padding: 10px;
}

.categoryTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #343434;
    margin-bottom: 0;
    font-family: 'Gordita';
    ;
}

.categoryList {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 26px !important;
    padding: 0 !important;
    flex-wrap: wrap;
}

.categoryList .categoryList-item {
    height: 30px;
    text-align: center;
    background: #F3F6F8;
    border-radius: 6px;
    margin-right: 12px;
    margin: 8px 12px 8px 0px;
    padding: 5px 20px;
    width: auto;
    white-space: nowrap;
}

.categoryList .subcategoryList-item {
    width: auto;
    min-width: 80px;
    height: 32px;
    text-align: center;
    background: rgba(22, 75, 122, 0.08);
    border-radius: 6px;
    margin: 8px 12px 8px 0px;
    padding: 5px 20px;
    white-space: nowrap;
}

.categoryList .categoryList-item span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6A8CAA;
    font-family: 'Gordita';
}

.categoryList .subcategoryList-item span {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: rgba(22, 75, 122, 0.64);
    font-family: 'Gordita';
}

.categoryList .more-subcategory {
    border: 1px solid #6A8CAA;
    background: unset;
    color: #6A8CAA;
}

.customFile-input input {
    color: #343434;
}

.customFile-input input::-webkit-file-upload-button {
    visibility: hidden;
}

.customFile-input input::before {
    content: 'Browse';
    color: #6A8CAA;
    display: inline-block;
    background: rgba(57, 161, 255, 0.08);
    border-radius: 0 8px 8px 0;
    padding: 5px 30px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 36px;
    right: 0;
    top: 0;
    position: absolute;
    text-align: center;
    display: flex;
    align-items: center;
}

.profileBadge {
    margin-bottom: 40px;
}

.profileBadge .MuiBadge-badge {
    top: unset;
    bottom: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #6A8CAA;
    border: 4px solid #FFFFFF;
    cursor: pointer;
}

.profileBadge .MuiBadge-badge img {
    filter: brightness(10);
}

.profileBadge .MuiInput-input {
    display: none;
}

.profileBadge .MuiIconButton-root {
    padding: 0;
}

.profileEditBox {
    background-color: rgba(106, 140, 170, 0.16);
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(106, 140, 170, 0.32);
    overflow: hidden;
}

.profileEditBox img.default {
    width: 100%;
    border-radius: 100px;
}

.profileEditBox img.profile_image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.registered-formGrid {
    margin-top: -11px;
}

.registered-formGrid .checkboxInput .MuiCheckbox-root {
    order: 2;
    padding: 3px;
}

.checkboxInput .MuiCheckbox-root {
    color: #6A8CAA !important;
}

.registered-formGrid .checkboxInput {
    margin-left: 0;
}

.registered-formGrid .formControl .inputField {
    margin-top: 0;
}

.registered-formGrid .MuiInput-formControl {
    margin-top: 0 !important;
}

.datepicker-formGrid {
    align-items: center;
}

.datepicker-formGrid .MuiOutlinedInput-input {
    padding: 12.5px 0 12.5px 8px;
    font-family: "Gordita";
}

.datepicker-formGrid .formGrid-inner {
    width: 46%;
    margin-bottom: 0 !important;
}

.datepicker-formGrid .MuiOutlinedInput-notchedOutline {
    border-color: #C8CFD5;
}

.datepicker-formGrid .MuiButtonBase-root {
    color: #6A8CAA;
}

.datepicker-formGrid .MuiFormControl-marginNormal {
    margin: 0;
}

.datepicker-formGrid .MuiInput-formControl {
    margin-top: 4px !important;
}

/*------------- added section style --------------*/
.my_profile-inner .formGrid .formControl .website-label,
.my_profile-inner .formGrid .formControl .idProof-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #343434;
    opacity: 1;
}

.my_profile-inner .formGrid .formControl .project-link {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0E101A;
    text-decoration: none;
}

.my_profile-inner .formGrid .formControl .idProof-name {
    margin-top: 8px;
    display: flex;
    align-items: center;

}

.my_profile-inner .formGrid .formControl .idProof-name span {
    width: 40px;
    height: 40px;
    background: rgba(106, 140, 170, 0.16);
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.my_profile-inner .formGrid .formControl .idProof-name .downloadBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #548CA8;
    text-decoration: none;
    margin-left: 8px;
}

.text-center {
    text-align: center;
}

/*-----  multiselect-permission style---------*/
.multiselect-permission .MuiInput-formControl {
    min-height: 48px;
    height: auto !important;
}

.projects .projectsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.projects .projectsList .projectListItem {
    /* max-width: 48.5%;
    min-width: 48.5%; */
    width: 100%;
    margin-bottom: 24px;
}

/* .freelancer_profile-details .profile-inner {
    padding: 0;
} */
.profile-inner .MuiTabs-root {
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
    padding: 12px 24px 0;
}

.profile-inner .tab-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #0E101A;
    opacity: 0.56;
    font-family: 'Gordita';
}

.profile-inner .Mui-selected.tab-title {
    font-weight: 700;
    opacity: 1;
}

.profile-inner .tab-title .MuiTab-wrapper {
    display: block;
}

.MuiTabs-indicator {
    background-color: #548CA8 !important;
}

.profile-inner .MuiBox-root {
    /* padding: 24px; */
}

.aboutContent .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #343434;
    margin-bottom: 0;
    font-family: 'Gordita';
    word-break: break-all;
}
.aboutContent .content body, .aboutContent .content h1, .aboutContent .content h2, .aboutContent .content h3, .aboutContent .content h4, .aboutContent .content h5, .aboutContent .content h6{
    font-weight: 700;
    margin-top: 0;
    font-size: 21px;
    margin-bottom: 15px;
}

.content a {
    color: #548CA8;
    text-decoration: none;
    padding-left: 8px;
}

.content .read-moreLink {
    color: #548CA8;
    text-decoration: none;
    padding-left: 8px;
}

.read-moreLink {
    color: #548CA8;
    text-decoration: none;
    padding-left: 8px;
}

.aboutContent .content.location,
.aboutContent .content.language {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #0E101A;
}

.projects .projectsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.work-timeline .MuiTimelineItem-root:before {
    display: none;
}

.work-timeline .MuiTimelineContent-root {
    padding-top: 0;
    margin-top: -10px;
    font-family: 'Gordita';
    padding-bottom: 32px;
}

.work-timeline .MuiTimelineDot-root {
    margin: 0;
    background-color: #08D6A8;
    box-shadow: none;
}

.work-timeline .MuiTimelineDot-outlinedGrey {
    background-color: #08D6A8;
    border-color: #08D6A8;
    outline: 2px solid #fff;
    outline-offset: -7px;
}

.work-timeline .MuiTimelineConnector-root {
    border: 1px dashed rgba(106, 140, 170, 0.4);
    width: auto;
    background-color: unset;
}

.work-timeline .year {
    background: #6A8CAA14;
    border-radius: 6px;
    padding: 8px 18px;
    text-align: center;
    width: fit-content;
    margin-bottom: 8px;
}

.work-timeline .year p {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #6A8CAA;
    margin-bottom: 0;
}

.work-timeline .profile {
    margin-bottom: 12px;
}

.work-timeline .profile p.company-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #08D6A8;
    margin-bottom: 0;
}

.work-timeline .profile p.designation {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6A8CAA;
    margin-bottom: 0;
}

.work-timeline .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(52, 52, 52, 0.72);
    word-break: break-all;
}

.MuiFormControl-root.MuiTextField-root {
    width: 100%;
}

.status {
    font-family: 'Gordita' !important;
    font-weight: 500 !important;
    color: #0e101a !important;
    opacity: 0.72;
    font-size: 13px !important;
}

.multiSelect-Textfield {
    margin-top: 20px !important;
}

.multiSelect-Textfield .MuiOutlinedInput-root {
    padding: 5px !important;
    border-radius: 8px !important;
}

.multiSelect-Textfield fieldset.MuiOutlinedInput-notchedOutline {
    border: 1px solid #C8CFD5 !important;
}

.description .multiSelect-Textfield {
    margin-top: 0px !important;
}


/*------------ certificateCard style-----------------*/

.certificateCard {
    display: flex;
    margin-bottom: 40px;
}

.certificateCard:last-child {
    margin-bottom: 0px;
}

.certificateCard .certificateImg {
    padding: 8px;
    background: #ECF6FF;
    border-radius: 12px;
    margin-right: 16px;
    cursor: pointer;
    height: 124px;
}

.certificateCard .certificateImg img {
    width: 164px;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;

}

.certificateCard .content {
    font-family: 'Gordita';
}

.certificateCard .content .certificate-title {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;
}

.certificateCard .content .certificate-title p {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #0E101A;
    margin-bottom: 0;
}

.certificateCard .content .certificate-title p.date {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #6A8CAA;
    margin: 0 12px;
}

.certificateCard .content .about-certi p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(14, 16, 26, 0.72);
}

/*------------ certificateCard style end-----------------*/
.profile-subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #343434;
    margin-bottom: 8px !important;
    margin-top: 24px !important;
}

/*--------- work experience panel style -------------*/
.work-history {
    background: #FFFFFF;
    border: 1px solid rgba(99, 180, 253, 0.16);
    border-radius: 12px;
    padding: 24px 24px 0px;
    position: relative;
    margin-bottom: 24px;
}

.work-history .history-count {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0E101A;
    margin-bottom: 0;
}

.work-history .closebtn {
    position: absolute;
    right: 24px;
    top: 24px;
}

.work-history .closebtn img {
    width: 12px;
    height: 12px;
}

.work-history .datepicker-formGrid .formGrid-inner {
    width: 48%;
    margin-bottom: 0 !important;
}

.work-history .datepicker-formGrid .MuiFormControl-marginNormal {
    margin: 0;
    width: 100%;
}

.multiSelect-Textfield {
    width: 100%;
}

.multiSelect-Textfield .MuiInput-formControl {
    padding: 4px 16px !important;
    width: 100%;
}

.multiSelect-Textfield .MuiOutlinedInput-root {
    padding: 5px !important;
    margin-top: 20px;
    border-radius: 8px;
}

.multiSelect-Textfield fieldset.MuiOutlinedInput-notchedOutline {
    border: 1px solid #C8CFD5 !important;
}

/*--------- work experience panel style end-------------*/
/*----------- browseBtn -------*/
.browseBtn {
    width: 100%;
}

.filenameInput {
    margin-right: auto;
    width: calc(100% - 110px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: none;
}

.customFile-input-label {
    width: 100%;
}

.formControl .inputField {
    border: 1px solid #C8CFD5;
    border-radius: 8px;
    padding: 10px 16px 9px;
    margin-top: 20px;
    font-size: 16px;
    height: 48px;
    font-family: 'Gordita';
}

.selectFileFormcontrol .inputField {
    border: 1px dashed #C8CFD5 !important;
    padding: 10px 0px !important;
    margin-bottom: 0px !important;
}

.language-container {
    margin-top: 30px;
    width: 100%;
    background: #FFFFFF;
    border: 2px solid transparent;
    border-radius: 12px;
    position: relative;
    margin-bottom: 24px;
    box-shadow: 0px 0px 8px rgba(23, 47, 69, 0.08);
}

.languageTitle {
    background: #476072;
    border-radius: 8px 8px 0px 0px;
    padding: 12px 24px;
}

.languageTitle p {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
    margin-bottom: 0px;
}

.languageContent {
    padding: 24px 24px 0px;
}

.language-container .formGrid .formGrid-inner {
    width: 45%;
}

.deleteDiv {
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 24px;
}

.language-container .no_of_lang {
    display: flex;
    align-items: center;
}

.language-container .no_of_lang p {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #0E101A;
    opacity: 0.72;
}

.addLang {
    margin-left: 24px !important;
    padding: 0px 24px 24px;
}

span.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
    min-width: 25px;
    padding: 6px;
    text-align: center;
}

/*----------- browseBtn end-------*/
/*--------------- agency developer profile -----------------*/
.language-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.language-section .language-div {
    width: 48%;
}

.language-section .language-div .language_slider {
    color: rgba(14, 16, 26, 0.08) !important;
    height: 6px !important;
    border-radius: 6px !important;
}

.language-section .language-div .language_slider .MuiSlider-rail {
    height: 6px !important;
    border-radius: 6px !important;
}

.language-section .language-div .language_slider .MuiSlider-track {
    height: 6px !important;
    border-radius: 6px !important;
    background-color: #548CA8 !important;
}

.language-section .language-div .language_slider .MuiSlider-thumb {
    display: none !important;
}

/*---- file input style ------------*/
.customFile-input input {
    color: #343434;
}

.customFile-input input::-webkit-file-upload-button {
    visibility: hidden;
}

.customFile-input input::before {
    content: 'Browse';
    color: #6A8CAA;
    display: inline-block;
    background: rgba(57, 161, 255, 0.08);
    border-radius: 0 8px 8px 0;
    padding: 5px 30px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 36px;
    right: 0;
    top: 0;
    position: absolute;
    text-align: center;
    display: flex;
    align-items: center;
}

/*----------- browseBtn -------*/
.browseBtn {
    width: 100%;
}

.filenameInput {
    margin-right: auto;
    width: calc(100% - 110px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: none;
    font-family: 'Gordita';
}

.selectFileBtn {
    width: 100%;
}

.selectFileBtn .browseText {
    position: relative !important;
    text-align: center;
    width: 100%;
    justify-content: center;
}

.selectFileFormcontrol .inputField {
    border: 1px dashed #C8CFD5 !important;
    padding: 10px 0px !important;
}

.closebtn {
    cursor: pointer;
}


/*----------- browseBtn end-------*/
/*-------- reason of rejection style -----------*/
.rejection-container {
    padding: 40px 40px 30px;
}

.rejection-container .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    color: #0E101A;
    margin-bottom: 16px;
}

.rejection-container .rejection-container-inner {
    background: #FFF5F7;
    border-radius: 6px;
    padding: 20px;
    border-left: 4px solid #F90E38;
}

.rejection-container-inner .admin-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: rgba(14, 16, 26, 0.72);
    margin-bottom: 16px;
}

.rejection-container-inner .reason-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(14, 16, 26, 0.72);
}

span.MuiAutocomplete-tag {
    min-width: 35px;
    cursor: pointer;
    text-align: center;
}

.selectFileFormcontrol .inputField {
    border: 1px dashed #C8CFD5 !important;
    padding: 10px 0px !important;
}

.registered-formGrid .formControl .inputField {
    margin-top: 0;
}

.certificateView .docCertificateView .idProof-name {
    margin-top: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.certificateView .docCertificateView .idProof-name span {
    width: 40px;
    height: 40px;
    background: rgba(106, 140, 170, 0.16);
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.certificateView .docCertificateView .idProof-name span img {
    width: 22px;
    margin: auto;
}

.certificateView .docCertificateView .idProof-name .downloadBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #548CA8;
    text-decoration: none;
    margin-left: 8px;
}

.certificate_table .certificateImg img {
    height: 100%;
    object-fit: contain;
}

/*-------- reason of rejection style end -----------*/
/*----------- pagination style --------------*/
.pagination-container {
    margin-top: 24px;
}

.pagination-container .pagination-inner {
    margin: auto;
}

.pagination-container .MuiPaginationItem-root {
    color: #6E7377;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    line-height: 2.143;
}

.pagination-container .MuiPaginationItem-previousNext {
    background-color: #fff;
    border: none;
}

.pagination-container .MuiPaginationItem-root.Mui-selected {
    color: #fff;
    background: #548CA8;
    box-shadow: 0px 8px 24px rgba(57, 129, 247, 0.32);
    border-radius: 8px;
}

.pagination-container .MuiPaginationItem-root:hover {
    background: #3981F712;
}

/*----------- pagination style end--------------*/

/**-------------------------------------------------------------------**/
/**------------------- responsive style ------------**/
/**-------------------------------------------------------------------**/
.form-line-grid .MuiFormControl-fullWidth {
    margin-bottom: 20px;
}

.form-line-grid .MuiTextField-root {
    margin: 0;
}

.agency-id-dev-request {
    text-decoration: none !important;
    color: #548CA8 !important;
}

@media(max-width: 600px) {
    .formGrid .formGrid-inner {
        width: 100%;
    }
}