.transform-scale-1 {
  transform: scale(1) !important;
}

.bg-dark {
  background-color: #0000001c !important;
}

.bg-light {
  background-color: #ffffff1c !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.p-6-12 {
  padding: 6px 12px !important;
}

.p-24-0 {
  padding: 24px 0px !important;
}

.p-5-20 {
  padding: 5px 20px !important;
}

.singleTableCell {
  border-radius: 8px !important;
}

.disabled-pointer-event {
  pointer-events: none;
}

.skeleton-b-l {
  border-left: none !important;
}