/* .developer-main-request {
    margin-top: -40px;
    width: 40%;
    margin-left: auto;
    margin-right: 50px;
} */

.MuiAutocomplete-popper {
  z-index: 300 !important;
}

.developer_request_column .new {
  background: rgba(126, 191, 20, 0.08);
  border-radius: 8px;
  padding: 8px 16px;
  color: #7ebf14;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.developer_request_column .update {
  background: rgba(255, 144, 41, 0.08);
  border-radius: 8px;
  padding: 8px 16px;
  color: #ff9903;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.developer-request-list .MuiAutocomplete-popper {
  top: 30px !important;
}
.agency_list.developer-request-list {
  min-width: 160px;
}
.header-tab-button .MuiButton-label{
  align-items: flex-start;
}
.subscription-form .MuiAutocomplete-popper {
  top: 65px !important;
}

@media (max-width: 600px) {
  .MUIDataTableToolbar-titleText-114 {
    text-align: left !important;
  }

  .developer-request-list {
    width: 100%;
    position: unset;
    margin-bottom: 20px;
  }

  .MUIDataTableToolbar-actions-111 {
    text-align: right !important;
  }

  .developer-main-request {
    width: unset;
  }
} 

